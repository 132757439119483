<template>
  <div>
    <!--begin::Dashboard-->
    
    <div class="row">
      <div class="col-lg-12 col-xxl-12">
        <div class="card card-custom card-stretch gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark"
                >Listado de usuarios</span
              >
              <span class="text-muted mt-3 font-weight-bold font-size-sm"
                >{{ rows }} Administradores encontradas en el sistema</span
              >
            </h3>
            <div class="card-toolbar">
              <a
                href="#/users/new"
                class="btn btn-primary font-weight-bolder font-size-sm mr-3"
                >Nuevo Usuario</a
              >
              <!-- <a href="#" class="btn btn-danger font-weight-bolder font-size-sm"
                >Create</a
              > -->
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-0 pb-3">
            <div>
              <div>
                <div class="input-group input-group-lg input-group-solid mb-8">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="la la-search"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control form-control-solid form-control-lg inline"
                    name="address1"
                    placeholder="Busqueda"
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    hide-details
                  />
                </div>
              </div>
              <div class="tab-content position-relative">
                <LoaderPartial v-if="showLoader"></LoaderPartial>
                <!--begin::Table-->
                <div class="table-responsive">
                  <table
                    class="table table-head-custom table-vertical-center table-head-bg table-borderless"
                  >
                    <thead>
                      <tr class="text-left">
                        <th class="pl-7 hover" @click="sortListFor('name')">
                          <span class="text-dark-75"
                            >Nombre
                            <i
                              class="fa fa-angle-up"
                              aria-hidden="true"
                              v-if="sortingList.name == 'DESC'"
                            ></i>
                            <i
                              class="fa fa-angle-down"
                              aria-hidden="true"
                              v-if="sortingList.name == 'ASC'"
                            ></i>
                          </span>
                        </th>
                        <th class="hover" @click="sortListFor('email')">
                          Email
                          <i
                            class="fa fa-angle-up"
                            aria-hidden="true"
                            v-if="sortingList.email == 'DESC'"
                          ></i>
                          <i
                            class="fa fa-angle-down"
                            aria-hidden="true"
                            v-if="sortingList.email == 'ASC'"
                          ></i>
                        </th>
                        <!-- <th>Suscripción</th> -->
                        <th class="">Rol</th>
                        <th>Estado</th>
                        <th class="hover" @click="sortListFor('createdAt')">
                          Creación
                          <i
                            class="fa fa-angle-up"
                            aria-hidden="true"
                            v-if="sortingList.createdAt == 'DESC'"
                          ></i>
                          <i
                            class="fa fa-angle-down"
                            aria-hidden="true"
                            v-if="sortingList.createdAt == 'ASC'"
                          ></i>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(item, i) in users">
                        <tr v-bind:key="i">
                          <td class="pl-0 py-8">
                            <div class="d-flex align-items-center">
                              <div class="symbol symbol-50 symbol-light mr-4">
                                <span
                                  class="symbol-label"
                                  style="overflow:hidden;"
                                >
                                  <inline-svg
                                    src="media/svg/icons/General/User.svg"
                                  />
                                </span>
                              </div>
                              <div>
                                <a
                                  :href="`#/users/${item.id}`"
                                  class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                                  >{{ item.name }}</a
                                >
                                <span
                                  class="text-muted font-weight-bold d-block"
                                  v-if="!!item.nickname"
                                  >{{ item.nickname }}</span
                                >
                                <span
                                  class="text-muted font-weight-bold d-block"
                                  v-if="!item.nickname"
                                  >{{ item.nickname }}</span
                                >
                              </div>
                            </div>
                          </td>
                          <td>
                            <span class="text-muted font-weight-bold">{{
                              item.email
                            }}</span>
                          </td>
                          <!-- <td>
                            <span class="text-muted font-weight-bold">{{ item.suscriptions.join(", ") }}</span>
                          </td> -->
                          <td>
                            <span class="text-muted font-weight-bold"
                              >{{item.role_objects[0].name}}</span
                            >
                          </td>
                          <td>
                            <b-badge
                              class="mr-1"
                              variant="success"
                              v-if="item.active"
                              >Activo</b-badge
                            >
                            <b-badge
                              class="mr-1"
                              variant="danger"
                              v-if="!item.active"
                              >Inactivo</b-badge
                            >
                            <span class="text-muted font-weight-bold d-block">{{
                              item.company_desc
                            }}</span>
                          </td>
                          <td>
                            <!-- <img
                              src="/media/logos/stars.png"
                              alt="image"
                              style="width: 5rem"
                            /> -->
                            <span class="text-muted font-weight-bold d-block">{{
                              new Date(item.createdAt)
                                .toLocaleString()
                                .split(" ")[0]
                            }}</span>
                          </td>
                          <td class="pr-0 text-right">
                            <b-dropdown id="dropdown-1" text="Acciones">
                              <b-dropdown-item :href="`#/users/${item.id}`"
                                >Editar</b-dropdown-item
                              >
                              <b-dropdown-item
                                v-if="item.active && currentUser.id != item.id"
                                @click="disableResource(item.id)"
                                ><span style="color:red;"
                                  >Desactivar</span
                                ></b-dropdown-item
                              >
                              <b-dropdown-item
                                v-if="!item.active && currentUser.id != item.id"
                                @click="enableResource(item.id)"
                                ><span style="">Activar</span></b-dropdown-item
                              >
                              <b-dropdown-item
                                v-if="currentUser.id != item.id"
                                @click="deleteResourceModal(item)"
                                ><span style="color:red;"
                                  >Eliminar</span
                                ></b-dropdown-item
                              >
                              <!-- <b-dropdown-divider></b-dropdown-divider>
                              <b-dropdown-item active>Active action</b-dropdown-item>
                              <b-dropdown-item disabled>Disabled action</b-dropdown-item> -->
                            </b-dropdown>
                            <template>
                              <v-dialog
                                v-model="dialog"
                                max-width="520"
                                persistent
                                :retain-focus="false"
                              >
                                <v-card class="py-5 px-2">
                                  <v-card-title class="headline">
                                    <span
                                      >¿Está seguro que desea borrar este
                                      usuario?</span
                                    >
                                  </v-card-title>
                                  <v-card-text>
                                    Esta acción no puede ser reestablecida. Si
                                    aun así desea continuar, escriba el nombre
                                    del usuario (<b>{{
                                      deletableTenant.name
                                    }}</b
                                    >) y luego presione en Eliminar
                                    permanentemente. <br /><br />
                                    <input
                                      type="text"
                                      class="form-control form-control-solid form-control-lg inline mb-2"
                                      name="deletable-name"
                                      :placeholder="
                                        `escribe: ${deletableTenant.name}`
                                      "
                                      v-model="deletableTenantName"
                                      hide-details
                                    />
                                  </v-card-text>

                                  <v-card-actions
                                    class="justify-content-center"
                                  >
                                    <button
                                      class="btn btn-secondary font-weight-bolder font-size-sm mr-3"
                                      @click="
                                        () => {
                                          dialog = false;
                                        }
                                      "
                                    >
                                      Cancelar
                                    </button>
                                    <button
                                      class="btn btn-primary font-weight-bolder font-size-sm mr-3"
                                      :disabled="
                                        deletableTenant.name !=
                                          deletableTenantName
                                      "
                                      @click="
                                        () => {
                                          dialog = false;
                                          deleteResource(deletableTenant.id);
                                        }
                                      "
                                    >
                                      Eliminar permanentemente
                                    </button>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </template>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                  <div>
                    <div class="row">
                      <div class="col m-1 ">
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                          page-click=""
                          aria-controls="my-table"
                        ></b-pagination>
                      </div>
                      <div class="col m-1 text-right">
                        Elementos por pagina
                        <b-form-select
                          v-model="perPage"
                          :options="optionPerPage"
                          class="mb-3"
                          style="width:70px;"
                          value-field="item"
                          text-field="name"
                          disabled-field="notEnabled"
                        ></b-form-select>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Table-->
              </div>
            </div>
          </div>
          <!--end::Body-->
        </div>
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<style scoped>
table th.hover {
  cursor: pointer;
}
table th.hover:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>

<script>
//import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import LoaderPartial from "@/view/content/LoaderPartial.vue";

export default {
  name: "users",
  data() {
    return {
      resource: "admins",
      search: "",
      users: [],
      roles: [],
      deletableTenant: {},
      deletableTenantName: "",
      currentPage: 1,
      rows: 0,
      perPage: 5,
      optionPerPage: [5, 10, 25, 50, 100],
      sortingList: {},

      dialog: false,
      showLoader: true
    };
  },
  components: {
    LoaderPartial
  },
  async mounted() {
    await this.getCurrentUser();
    await this.getResourceRoleList();
    this.getResourceList();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Usuarios" }]);
    console.log(this.currentUser, this.currentUser.id);
  },
  watch: {
    search: function() {
      this.getResourceList();
    },
    perPage: function() {
      this.getResourceList();
    },
    currentPage: function() {
      this.getResourceList();
    }
  },
  methods: {
    sortListFor(row) {
      if (!this.sortingList[row]) {
        this.sortingList = []; // Clear?
        this.sortingList[row] = "DESC";
      } else if (this.sortingList[row] == "ASC") this.sortingList[row] = "DESC";
      else if (this.sortingList[row] == "DESC") this.sortingList[row] = "ASC";

      this.getResourceList();
    },
    getQuery() {
      let search = this.search.toLowerCase();
      let query = {
        filter: {
          limit: this.perPage,
          skip: this.perPage * (this.currentPage - 1),
          order: Object.keys(this.sortingList).map(
            key => `${key} ${this.sortingList[key]}`
          ),
          where: {
            or: [
              { name: { like: `${search}`, options: "i" } },
              { email: { like: `${search}`, options: "i" } },
              { id: { like: `${search}`, options: "i" } }
            ]
          }
        }
      };
      return query;
    },
    async getCurrentUser() {
      let { data } = await ApiService.get(`admins`, "me");
      console.log("ME", data);
      this.currentUser = data;
    },
    async getResourceCount() {
      let { data } = await ApiService.query(`${this.resource}/count`, {
        where: this.getQuery().filter.where
      });
      this.rows = data.count;
      return data.count;
    },
    
    async getResourceList() {
      this.showLoader = true;
      this.getResourceCount();

      let users = await ApiService.query(this.resource, this.getQuery());
      
      console.log(users);
      let data = users.data;
      this.users = data;
      this.users = data.map(user => {
        user.role_objects = [];
        user.roles.forEach(roleId => {
          let role_obj = this.roles.find(role => roleId == role.id );
          console.log(roleId, role_obj )
          user.role_objects.push( role_obj );
        })

        return user;
      });
      this.showLoader = false;
      console.log("Users with roles",this.users);
    },
    async getResourceRoleList(){
      let {data} = await ApiService.get(`${this.resource}`, "roles");
      console.log("roles",data)
      this.roles = data;
    },
    async getResourceRole(id) {
      let { data } = await ApiService.get(`${this.resource}/roles`, id);
      //console.log(data);
      return data;
    },
    async deleteResource(id) {
      ApiService.delete(`${this.resource}/${id}`)
        .then(() => {
          this.getResourceList();

          Swal.fire({
            title: "",
            text: `El usuario ${this.deletableTenantName} ha sido eliminado exitosamente!`,
            icon: "info",
            confirmButtonClass: "btn btn-secondary"
          });
          this.deletableTenant = {};
          this.deletableTenantName = "";
        })
        .catch(() => {
          console.log("Not deleted");
        });
    },
    deleteResourceModal(item) {
      this.deletableTenant = item;
      this.dialog = true;
    },
    async disableResource(id) {
      ApiService.patch(`${this.resource}/${id}`, { active: false })
        .then(() => this.getResourceList())
        .catch(() => {
          console.log("Not disabled");
        });
    },
    async enableResource(id) {
      ApiService.patch(`${this.resource}/${id}`, { active: true })
        .then(() => this.getResourceList())
        .catch(() => {
          console.log("Not enabled");
        });
    },
    getResourceRoleName(id) {
      return new Promise(resolve => {
        this.getResourceRole(id).then(tenant => {
          resolve(tenant.name);
        });
      });
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  },
  computed: {
    //...mapGetters(["currentUser"]),
  }
};
</script>
